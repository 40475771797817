<template>
  <a-modal
    id="validatetb-inference-modal"
    v-model:visible="showInferenceModal"
    width="100%"
    wrap-class-name="full-modal"
    :footer="null"
    destroy-on-close
    :body-style="{ padding: '10px 12px' }"
    @cancel="handleInferenceModalClose"
  >
    <template #closeIcon>
      <close-outlined id="validatetb-inference-modal-close-btn" />
    </template>
    <template #title>
      <div class="d-flex">
        Validation
        <span
          v-if="translationLanguage"
          class="d-flex align-items-center ml-auto mr-5"
        >
          <a-typography-text class="bold"> Select Language: </a-typography-text>
          <a-select
            :value="selectedLang"
            :options="languageOptions"
            class="w-20 mx-2"
            size="small"
            @change="(value) => setSelectedLanguage(value)"
          />
        </span>
      </div>
    </template>
    <inference-component
      ref="inferenceComp"
      :video="selectedVideo"
      :steps-list="stepsList"
      :is-video-exist="isVideoExist"
      :selected-file-name="selectedFileName"
      :isfetching-video-url="isfetchingVideoUrl"
      @handleVisualization="
        (video) => $refs.videoList.handleVisualization(video)
      "
      @handleDownloadCSV="(video) => $refs.videoList.handleDownloadCSV(video)"
    />
  </a-modal>

  <a-modal
    id="validatetb-upload-videos-vald-modal"
    v-model:visible="showUploadModal"
    title="Upload Video(s) Validation"
    width="100%"
    wrap-class-name="full-modal"
    :footer="null"
    destroy-on-close
  >
    <upload-video
      :task-id="selectedTask"
      @changeTask="onChangeTask"
      @handleUploadedFiles="handleUploadedFiles"
    />
  </a-modal>

  <!-- Comments Modal -->
  <a-modal
    id="validatetb-comment-modal"
    v-model:visible="commentsModal"
    title="Comments"
    :footer="null"
    centered
  >
    <template #closeIcon>
      <close-outlined id="validatetb-comment-modal-close-btn" />
    </template>
    <a-list
      id="validatetb-comments-list"
      :data-source="comments"
      item-layout="horizontal"
      style="height: 40vh; overflow: auto"
      size="small"
    >
      <template #renderItem="{ item, index }">
        <a-list-item :id="'validatetb-comment-' + index">
          <a-comment :author="item.user_id.username" :content="item.comment">
            <template #actions>
              <delete-outlined
                :id="'validatetb-comment-delete-' + index"
                class="text-danger"
                @click="deleteComment(item)"
              />
            </template>
          </a-comment>
        </a-list-item>
      </template>
    </a-list>
    <a-comment>
      <template #content>
        <a-form-item>
          <a-textarea
            id="validate-tb-new-comment-input"
            v-model:value="newComment"
            :rows="4"
          />
        </a-form-item>
        <a-form-item>
          <a-button
            id="validatetb-new-comment-add-btn"
            html-type="submit"
            :loading="addingComment"
            type="primary"
            @click="handleAddComment"
          >
            Add Comment
          </a-button>
        </a-form-item>
      </template>
    </a-comment>
  </a-modal>

  <!-- Tags Modals -->
  <a-modal
    id="validatetb-tags-modal"
    v-model:visible="tagsModal"
    title="Tags"
    :footer="null"
    centered
  >
    <template #closeIcon>
      <close-outlined id="validatetb-tags-modal-close-btn" />
    </template>
    <div style="height: 30vh">
      <a-select
        id="validatetb-tags-modal-select"
        v-model:value="currentSelectedTag"
        show-search
        class="w-100"
        mode="multiple"
        placeholder="Select Tag"
        :disabled="loadingTags"
        :loading="loadingTags"
        :options="tagOptions"
        :filter-option="filterOption"
        @select="handleAddTag"
        @deselect="handleTagRemove"
      />
    </div>
  </a-modal>

  <a-row :gutter="[16, 16]">
    <a-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
      <a-select
        id="validatetb-select-task-input"
        ref="validate_task_select"
        show-search
        class="w-100"
        :value="selectedTask"
        :options="options"
        placeholder="Select task"
        :filter-option="filterOption"
        @change="handleTaskSelect"
      />
    </a-col>
    <a-col :xs="24" :sm="12" :md="4" :lg="4" :xl="4">
      <a-select
        id="validatetb-select-tag-input"
        v-model:value="current_selec_tag_list"
        show-search
        class="w-100"
        mode="multiple"
        :disabled="!selectedTask"
        :options="tag_options"
        placeholder="Select Tag"
        :filter-option="filterOption"
      />
    </a-col>

    <a-col class="d-flex" :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
      <a-button
        id="validatetb-upload-btn"
        type="primary"
        :disabled="!selectedTask"
        class="ml-auto"
        @click="showUploadModal = true"
      >
        Upload Video
      </a-button>
    </a-col>

    <a-col span="24">
      <video-list
        ref="videoList"
        :record-list="videoList"
        :records-count="videosCount"
        :next="next"
        :previous="previous"
        :sort-order="sortOrder"
        :records-for-polling="recordsForPolling"
        :statuses="statuses"
        :sort-by="sortBy"
        :loading="loading"
        :task-id="selectedTask"
        :fetch-presigned-url="fetchPresignedUrl"
        @changeLoading="(val) => (loading = val)"
        @pageChange="handlePageChange"
        @handleInferenceOpen="handleInferenceOpen"
        @updateRecord="updateRecord"
        @updateStatusOfRecord="updateStatusOfRecord"
        @goToTraceAnalytics="goToTraceAnalytics"
        @showCommentsModal="showCommentsModal"
        @showTagsModal="showTagsModal"
      />
    </a-col>
  </a-row>
</template>
<script>
import UploadVideo from './UploadVideoV2.vue';
import VideoList from './VideoListV2.vue';
import InferenceComponent from './Inference.vue';
import { useToast } from 'vue-toastification';
import httpClient from '../../../../service/httpClient.js';
import parseTaskList from '../../../shared/Helpers/parseTaskList';
import dateHelper from '../../../shared/Helpers/dateHelper';
import getFileNameFromUrl from '../../../shared/Helpers/getFileNameFromUrl';
import getPrevNextRoutes from '../../../shared/Helpers/getPrevNextRoute';
import { mapActions, mapGetters } from 'vuex';
import { validationSteps } from '../../../shared/Data/tour-steps';
import handleDDSpace from 'src/mixins/handleSpace';
import trainingStatuses from 'src/config/training-status-config.js';
import { DeleteOutlined, CloseOutlined } from '@ant-design/icons-vue';
import cycleTypes from 'src/config/cycle-type-config';
import VideoService from 'src/services/videos';
import { getSortedTask } from '../../../../utils/task';
import axios from 'axios';
import { evaluateExpression } from 'src/components/shared/Helpers/expressionHelper';

export default {
  components: {
    UploadVideo,
    VideoList,
    InferenceComponent,
    DeleteOutlined,
    CloseOutlined,
  },
  mixins: [handleDDSpace],

  setup() {
    const toast = useToast();
    return { toast };
  },

  data() {
    return {
      tag_options: [],
      current_selec_tag_list: [],
      current_video_list: [],
      current_tag: [],
      currentVideosCount: 0,
      currentSelectedTag: [],
      tagOptions: [],
      recordVideo: null,
      loadingTags: false,
      tags: [],
      activeCommentTagTab: '1',
      currentEntityName: '',
      currentRecordEntityId: null,
      addingComment: false,
      newComment: '',
      comments: [],
      commentsModal: false,
      tagsModal: false,
      orgTagsModal: false,
      tagName: '',
      tagDesc: '',
      isLoadingTag: false,
      orgTags: [],
      taskList: [],
      options: [],
      videoList: [],
      videosCount: 0,
      next: null,
      previous: null,
      currentPage: 1,
      isfetchingVideoUrl: false,
      showUploadModal: false,
      showInferenceModal: false,
      //===========================================
      sortBy: '',
      sortOrder: 'asc',
      stepsList: [],
      selectedVideo: null,
      recordsForPolling: [],
      pollingLimit: 10,
      statuses: [],
      loading: false,
      steps: validationSteps,
      pollingRecords: null,
      isVideoExist: true,
    };
  },

  computed: {
    ...mapGetters([
      'isTourRunning',
      'organization',
      'user',
      'taskName',
      'taskDetails',
      'selectedTask',
      'negativeSteps',
      'taskProcesses',
      'selectedLang',
      'translationLanguage',
    ]),
    selectedFileName() {
      return this.splitFileNameByUUID(
        getFileNameFromUrl(this.selectedVideo?.video_url)
      );
    },
    languageOptions() {
      return [
        { value: 'English', label: 'ENGLISH' },
        {
          value: `${this.translationLanguage}`,
          label: `${this.translationLanguage?.toUpperCase()}`,
        },
      ];
    },
  },

  watch: {
    current_selec_tag_list() {
      this.$refs.videoList.handlePageChange({ current: 1 });
      this.getValidationRecord(this.selectedTask);
    },

    videoList(list) {
      const unfinishedRecord = list
        .filter(
          (el) => !['failed', 'finished'].includes(el.processStatus.status.name)
        )
        .map((el) => el.id);
      this.recordsForPolling = unfinishedRecord.splice(0, this.pollingLimit);
    },

    recordsForPolling(list) {
      if (list.length > 0 && !this.pollingRecords) {
        this.pollingRecords = setInterval(
          () => this.handlePageChange(this.currentPage, false),
          5000
        );
      } else if (list.length === 0) {
        clearInterval(this.pollingRecords);
        this.pollingRecords = null;
      }
    },

    async selectedTask(value) {
      if (!value) {
        this.videoList = [];
        this.videosCount = 0;
        this.next = null;
        this.previous = null;
        return;
      }
      this.fetchTaskDetails(value);
      this.setSelectedLanguage('English');
      this.onChangeTask(value);
    },

    taskDetails(value) {
      if (!value) return;
      this.stepsList = parseTaskList(value);
      this.setTaskNameAndProcess(value);
    },

    taskProcesses(value) {
      if (!value) return;
      this.setIndexToStepsMapping();
    },

    selectedLang(value) {
      this.setStepTranslations(value);
    },
  },

  created() {
    this.getTrainedTasksList();
    this.getProcessStatuses();
    this.fetchOrgTags();
    const { prevRouter, nextRoute } = getPrevNextRoutes(this.$router);
    this.changeRouteConfig({ prevRouter, nextRoute });
    if (this.selectedTask) this.onChangeTask(this.selectedTask);
    if (this.taskDetails) this.stepsList = parseTaskList(this.taskDetails);
  },

  beforeUnmount() {
    clearInterval(this.pollingRecords);
    // this.clearTaskDetails();
  },

  methods: {
    ...mapActions([
      'changeRouteConfig',
      'changeRoute',
      'stopTour',
      'setSelectedTask',
      'fetchTaskDetails',
      'setSelectedLanguage',
      'clearTaskDetails',
      'setIndexToStepsMapping',
      'setTaskNameAndProcess',
      'setStepTranslations',
    ]),

    handleTaskSelect(val) {
      this.setSelectedTask(val);
      this.$refs.validate_task_select.blur();
    },

    async getTrainedTasksList() {
      const res = await httpClient.get(
        `organization/task/get_all_trained_tasks_of_organization?train_status=${trainingStatuses.trained}`
      );
      if (res === 'error') {
        this.toast.error('Error occured while fetching tasks!');
        return;
      }
      this.taskList = res.map((el) => ({
        id: el.task.id,
        taskName: el.task.taskName,
      }));
      this.options = getSortedTask(res.map((el) => el.task));
    },

    openTagsModal() {
      this.orgTagsModal = true;
      this.fetchOrgTags();
    },

    async handleTagRemove(tagId) {
      this.loadingTags = true;
      const entity_id = this.recordVideo.entity.id;
      const entityToDelete = this.tags.find(
        (t) => t.entity_id === entity_id && t.tag_name.id === tagId
      );
      if (!entityToDelete) return;

      const res = await httpClient.delete(
        `organization/entity/tags/`,
        entityToDelete.id,
        false,
        false,
        false
      );
      if (res === 'error') {
        console.log('error occured while removing tag');
      }

      this.loadingTags = false;
    },

    async deleteComment(comment) {
      this.comments = this.comments.filter((t) => t.id !== comment.id);

      const res = await httpClient.delete(
        'organization/task_record/comments/',
        comment.id
      );

      if (res === 'error') {
        this.toast.error('Error in deleting!');
        return;
      }
    },

    splitFileNameByUUID(fileName) {
      const ext = fileName?.split('.').at(-1);
      const regex =
        /.[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/;

      fileName = fileName?.split(regex)[0];

      if (fileName?.search(ext) == -1) {
        fileName += '.' + ext;
      }

      return fileName;
    },

    async fetchOrgTags() {
      this.isLoadingTag = true;
      const res = await httpClient.get('organization/task_record/tags', false);
      this.orgTags = res?.map((el) => ({
        value: el.id,
        label: el.tag_name,
      }));
      this.isLoadingTag = false;
    },

    async handleAddOrgTag() {
      this.isLoadingTag = true;
      const payload = {
        tag_name: this.tagName,
        descript: this.tagDesc || 'default',
        organization: this.organization,
      };
      const res = await httpClient.post(
        'organization/task_record/tags',
        payload,
        false,
        false,
        false
      );
      this.orgTags = [...this.orgTags, res];
      this.isLoadingTag = false;
      this.tagName = '';
      this.tagDesc = '';
    },

    async createEntity() {
      const payload = {
        name: this.recordVideo.id,
        organization: this.organization,
      };
      return await httpClient.post(
        'organization/entity/',
        payload,
        false,
        false,
        false
      );
    },

    async createComment() {
      const paylaod = {
        comment: this.newComment,
        entity_id: this.recordVideo.entity.id,
        user_id: localStorage.getItem('id'),
      };
      return await httpClient.post(
        `organization/task_record/comments`,
        paylaod,
        false,
        false,
        false
      );
    },

    async handleAddComment() {
      this.addingComment = true;

      if (!this.recordVideo.entity) {
        const res = await this.createEntity();
        const temp = { ...this.recordVideo };
        temp['entity'] = res;
        this.recordVideo = temp;
        console.log(this.recordVideo);
        this.updateTaskRecord(
          { entity: { id: this.recordVideo.entity.id } },
          this.recordVideo,
          false
        );
      }

      const comment_res = await this.createComment();
      comment_res['user_id'] = { id: comment_res.user_id, username: this.user };

      this.comments = [...this.comments, comment_res];
      this.newComment = '';
      this.addingComment = false;
    },

    async createTag(tagId) {
      const paylaod = {
        tag_name: tagId,
        entity_id: this.recordVideo.entity.id,
        user_id: localStorage.getItem('id'),
      };

      return await httpClient.post(
        `organization/entity/tags`,
        paylaod,
        false,
        false,
        false
      );
    },

    updateVideoList(id, updatedObj) {
      console.log(id);
      console.log(this.videoList);
      const temp = [...this.videoList];
      const index = temp.findIndex((v) => v.id === id);
      console.log(index);
      if (temp[index]) {
        temp[index] = {
          ...temp[index],
          ...updatedObj,
        };
      }
      this.videoList = temp;
      console.log(this.videoList);
      console.log(localStorage.getItem('id'));
    },

    updateTaskRecord(updatedData, videoToUpdate, alert = true) {
      return new Promise(async (resolve) => {
        this.updateVideoList(videoToUpdate.id, updatedData);
        const res = await httpClient.patch(
          'websocket_inference/validation_record/',
          videoToUpdate.id + '/',
          updatedData,
          false,
          false,
          false
        );
        resolve();
        if (res === 'error' && alert) {
          this.toast.success('Error occured while updating the record!');
        }
        if (alert) this.toast.success('Record updated successfully!');
      });
    },

    async handleAddTag(tagId) {
      this.loadingTags = true;
      if (!this.recordVideo.entity) {
        const res = await this.createEntity();
        const temp = { ...this.recordVideo };
        temp['entity'] = res;
        this.recordVideo = temp;
        this.updateTaskRecord(
          { entity: { id: this.recordVideo.entity.id } },
          this.recordVideo,
          false
        );
      }

      const tag_res = await this.createTag(tagId);
      tag_res['tag_name'] = this.orgTags.find(
        (ot) => ot.id === tag_res.tag_name
      );

      this.tags = [...this.tags, tag_res];
      this.loadingTags = false;
    },

    showTagsModal(data) {
      this.recordVideo = data.taskRecord;
      this.orgTags = data.orgTags;
      this.tags = data.entityTag;
      this.currentSelectedTag = data.entityTag.map((e) => e.tag_name.id);
      this.tagOptions = data.orgTags.map((el) => ({
        value: el.id,
        label: el.tag_name,
      }));
      this.tagsModal = true;

      console.log(this.recordVideo);
    },

    showCommentsModal(data) {
      this.recordVideo = data.taskRecord;
      this.comments = data.comments;
      this.commentsModal = true;
      console.log(this.recordVideo.id);

      console.log(this.recordVideo);
    },

    getTagString() {
      var tag_names = [];
      for (var i = 0; i < this.current_selec_tag_list.length; i++) {
        for (var j in this.tag_options) {
          if (this.current_selec_tag_list[i] === this.tag_options[j].value) {
            tag_names.push(this.tag_options[j].label);
          }
        }
      }
      return tag_names.join(',');
    },

    getUrl(taskId, page, tag_param) {
      let url = `websocket_inference/validation_records/${taskId}/?tag__in=${tag_param}`;
      url = page > 1 ? `${url}&page=${page}` : url;
      return url;
    },

    async getValidationRecord(taskId, page = 1, loading = true) {
      this.loading = loading && true;
      const tagNames = this.getTagString();

      if (tagNames.length === 0) {
        this.videoList = [];
        this.videosCount = 0;
        this.loading = false;
        return;
      }
      let url = this.getUrl(taskId, page, tagNames);

      const res = await httpClient.getData(url, false);
      if (res === 'error') {
        this.toast.error('Error occured while fetching Videos!');
        return;
      }
      this.videoList = res.results.map((videoObj) => {
        const { video_url, ...res } = videoObj;
        delete videoObj.per_frame_prediction_file_url;
        return {
          ...res,
          fileName: getFileNameFromUrl(video_url),
          isThumbnailExist: true,
        };
      });
      this.videosCount = res.count;
      this.loading = false;
    },

    async getProcessStatuses() {
      const res = await httpClient.getData(
        'process_status/process/validation/'
      );
      const { statuses } = res[0];
      this.statuses = statuses;
    },

    async handlePageChange(page, loading = true) {
      this.currentPage = page;
      this.getValidationRecord(this.selectedTask, page, loading);
    },

    async getUniqueFlushTags() {
      let tag_names = await httpClient.get(
        'websocket_inference/validation_record/' + this.selectedTask + '/tags'
      );
      if (tag_names === 'error') {
        this.toast.error('Error occurred while fetching unique tags!');
        this.tag_options = [];
      } else {
        for (var i = 0; i < tag_names.length; i++) {
          tag_names[i]['id'] = i + 1;
        }

        this.tag_options = tag_names.map((el) => ({
          value: el.id,
          label: el.tag,
        }));
      }
    },

    async onChangeTask(taskId) {
      await this.getUniqueFlushTags();
      this.current_selec_tag_list = [];
      const tag_id = this.tag_options.find((t) => t.label === 'Latest');
      if (tag_id) {
        this.current_selec_tag_list.push(tag_id.value);
      }
      this.getValidationRecord(taskId);
    },

    fetchPresignedUrl(video, isPredictionFile, predsFileFolderName) {
      const { bucket, filePath } = this.getS3FileDetails(
        video,
        isPredictionFile,
        predsFileFolderName
      );
      const payload = {
        bucket_name: bucket,
        object_path: filePath,
      };
      return new Promise(async (resolve) => {
        const [error, data] = await VideoService.getPresignedUrl(payload);
        // console.log({ error, data });
        if (error) {
          console.log('error fetching presigned url', { isPredictionFile });
        }
        resolve(data.presigned_url);
      });
    },

    getS3FileDetails(video, predictionsFile = false, predsFileFolderName = '') {
      let { fileName, id } = video;
      let filePath = '';
      fileName += predictionsFile ? '.json' : '';
      const bucket = `${this.organization}-videos`;
      const type = predictionsFile ? predsFileFolderName : 'Videos';
      if (predictionsFile && predsFileFolderName === 'Processed')
        filePath = `${this.organization}/${this.selectedTask}/${type}/${id}.json`;
      else filePath = `validation/${this.taskName}/${type}/${fileName}`;
      return { bucket, filePath };
    },

    async handleInferenceOpen(video) {
      this.showInferenceModal = true;
      this.isfetchingVideoUrl = true;
      if (!video.video_url) {
        video = await this.getFilesUrl(video);
        if (!video) return;
      }
      video['fps'] = await this.getVideoFPS(video);
      this.isfetchingVideoUrl = false;
      this.selectedVideo = { ...video };
      this.updateRecord(this.selectedVideo);
    },

    getVideoFPS(video) {
      return new Promise(async (resolve) => {
        let formData = new FormData();
        formData.append('video_path', video.video_url);
        const [, data] = await VideoService.getVideoMetaData(formData, false);
        let fps = 30;
        if (data.streams) {
          let { avg_frame_rate } = data.streams[0];
          fps = evaluateExpression(avg_frame_rate).toFixed(2);
        } else {
          this.isVideoExist = false;
        }
        resolve(fps);
      });
    },

    getFilesUrl(video) {
      return new Promise(async (resolve) => {
        const [video_url, preds_url, frames_steps_preds_url] =
          await Promise.all([
            this.fetchPresignedUrl(video),
            this.fetchPresignedUrl(video, true, 'Predictions'),
            this.fetchPresignedUrl(video, true, 'Processed'),
          ]);

        if (video_url === 'error') {
          this.toast.error('Error occurred while fetching url!');
          return;
        }
        return resolve({
          ...video,
          video_url: video_url,
          per_frame_prediction_file_url: preds_url,
          frames_steps_preds_url: frames_steps_preds_url,
        });
      });
    },

    getPredictions(predictionsUrls) {
      return new Promise(async (resolve) => {
        var responses = [];
        for (const url of predictionsUrls) {
          try {
            const { data } = await axios.get(url);
            responses.push(data);
          } catch (error) {
            responses.push(undefined);
          }
        }
        resolve(responses);
      });
    },

    handleInferenceModalClose() {
      this.selectedVideo = false;
      this.isVideoExist = true;
      this.$refs.inferenceComp?.resetStepsTime();
    },

    getPayload() {
      return {
        Task: this.selectedTask,
        organization: localStorage.getItem('organization'),
        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
    },

    async addValidationRecord(data) {
      return await httpClient.post(
        'websocket_inference/validation_record/',
        data,
        false,
        true,
        false
      );
    },

    getAsyncResponses(fileUrlList, payload) {
      return fileUrlList.map((fileUrl) =>
        this.addValidationRecord({
          ...payload,
          ['video_url']: fileUrl['file_url'],
          ['thumbnail_path']: fileUrl['thumbnail_path'],
          ['file_name']: fileUrl['thumbnail_path']
            .split('Videos/')
            .pop()
            .replace('/', ''),
        })
      );
    },

    // async getRecordDetails(id) {
    //   return await httpClient.getData(
    //     `websocket_inference/validation_record/${id}/`,
    //     false
    //   );
    // },

    // getValidationRecords(recordIds) {
    //   return recordIds.map((id) => this.getRecordDetails(id));
    // },

    // async fetchValidationRecords(idList) {
    //   const validationRecordResponses = this.getValidationRecords(idList);
    //   let recordList = [];
    //   for await (const res of validationRecordResponses) {
    //     recordList.push(res);
    //   }

    //   recordList.sort((a, b) => b.id - a.id);
    //   this.videoList = [...recordList, ...this.videoList];
    //   // this.loading = false;
    // },

    async handleUploadedFiles(fileUrlList) {
      // this.$refs.uploadVideo.toggleModal();
      this.showUploadModal = false;
      this.loading = true;
      const payload = this.getPayload();
      const responses = this.getAsyncResponses(fileUrlList, payload);
      const idList = [];

      for await (const res of responses) {
        idList.push(res.id);
      }
      this.getValidationRecord(this.selectedTask, this.currentPage);
      this.loading = false;
      // this.fetchValidationRecords(idList);
    },

    updateRecord(validationRecord) {
      const temp = [...this.videoList];
      const index = temp.findIndex((el) => el.id === validationRecord.id);
      temp[index] = validationRecord;
      this.videoList = temp;
    },

    updateStatusOfRecord({ recordId, status }) {
      const temp = [...this.videoList];
      const index = temp.findIndex((el) => el.id === recordId);
      const targetRecord = temp[index];
      targetRecord.processStatus.status.name = status;
      temp[index] = targetRecord;
      this.videoList = temp;
    },

    goToTraceAnalytics({ page, startTime, endTIme }) {
      const dateFormat = 'MM-DD-YY HH:mm:ss';
      const params = {
        taskId: this.selectedTask,
        deviceId: JSON.stringify([localStorage.getItem('organization')]),
        startTime: dateHelper.formatDate(startTime, dateFormat),
        endTime: dateHelper.formatDate(endTIme, dateFormat),
        cycleType: cycleTypes['All Cylces'],
      };
      console.log({ params });

      if (page === 'Trace') {
        params.toast = true;
      }
      let routeData = this.$router.resolve({
        name: page,
        query: params,
      });
      window.open(routeData.href, '_blank');
    },
  },
};
</script>
<style scoped>
.Disconnected {
  color: rgb(255, 0, 0);
}
.Connected {
  color: rgb(76, 175, 80);
}
</style>
